/* Conteneur principal de la page */
.category-page {
  padding: 20px;
  margin: 0 10%; /* Marge de 10% à gauche et à droite */
}

.category-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left; /* Aligné à gauche */
}

.category-description {
  font-size: 1.1rem;
  margin-bottom: 30px;
  color: #555;
  text-align: left; /* Aligné à gauche */
}

/* Liste des produits */
.product-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cartes par ligne par défaut */
  gap: 20px;
  justify-items: center;  /* Centrer les cartes */
}

/* Adaptation responsive */
@media (max-width: 1024px) {
  .product-list {
    grid-template-columns: repeat(2, 1fr); /* 2 cartes par ligne sur les écrans plus petits */
  }
}

@media (max-width: 600px) {
  .product-list {
    grid-template-columns: 1fr; 
  }
}

/* Carte produit */
.product-card {
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 300px; /* Augmenter la largeur maximale pour donner un peu plus d'espace */
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;  /* Centrer tous les éléments */
  justify-content: space-between;  /* Espace entre l'image, le nom et les couleurs */
}

.product-image {
  max-width: 300px;
  max-height: 150px;
  border-radius: 8px;
}

.placeholder-image {
  width: 100%;
  height: 200px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa;
  font-size: 1rem;
  border-radius: 8px;
}

.product-info {
  margin-top: 15px;
}

.product-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.product-price {
  font-size: 1.1rem;
  margin-bottom: 15px;
}

/* Section des couleurs sous chaque produit */
.color-options {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;  /* Ajouter un peu d'espace entre le prix et les couleurs */
}

.color-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;  /* Rendre les boutons ronds */
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.color-button:hover {
  transform: scale(1.1);  /* Agrandir légèrement au survol */
}

/* Pour les petits écrans (mobile) */
@media (max-width: 600px) {
  .product-card {
    padding: 15px;  /* Moins d'espace sur mobile */
  }

  .category-title {
    font-size: 1.5rem;
  }

  .category-description {
    font-size: 1rem;
  }

  .product-name {
    font-size: 1rem;
  }

  .product-price {
    font-size: 1rem;
  }
}
