.product-page {
  display: flex;
  justify-content: center;
  padding: 20px 15px;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  box-sizing: border-box;
}

.product-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  gap: 30px;
  margin: 0 auto;
}

.carousel {
  position: relative;
  width: 50%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: 10px;
}

.carousel-image {
  width: 50%;
  height: 50%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.carousel-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.carousel-button.prev {
  left: 15px;
}

.carousel-button.next {
  right: 15px;
}

.product-info {
  width: 50%;
  padding: 0 15px;
  box-sizing: border-box;
}

.product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}

.product-info h1 {
  font-size: 1.5rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-price {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: auto;
}

.old-price {
  text-decoration: line-through;
  color: #888;
  font-size: 1.2rem;
}

.new-price {
  font-weight: bold;
  color: #000;
  font-size: 1.2rem;
}

.color-selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 15px;
}

.color-selection h3 {
  font-size: 1.1rem;
  margin: 0;
}

.color-options {
  display: flex;
  gap: 10px;
}

.color-button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  transition: border 0.2s;
}

.product-rating {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.product-rating img {
  width: 100px;
}

.product-rating span {
  font-size: 0.9rem;
}

.product-benefits {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;
}

.product-benefits li {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.check-icon {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}

.benefit-text {
  font-size: 1rem;
  line-height: 1.4;
  word-wrap: break-word;
  text-align: justify;
}

.benefit-text strong {
  white-space: nowrap;
}

.offers {
  margin-top: 25px;
}

.offers h3 {
  font-size: 1.1rem;
  margin-bottom: 30px;
}

.offer-options {
  display: flex;
  gap: 10px;
  max-width: 100%;
  justify-content: center;
  width: 100%; /* Largeur de référence */
  padding: 0 15px; /* Marges symétriques */
  box-sizing: border-box;
  max-width: 520px; /* Largeur fixe pour grand écran : 2x 250px + 10px gap */
}

.offer-box {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  max-width: 250px; /* Taille maximale de chaque boîte */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
  text-align: center;
  box-sizing: border-box;
}

.offer-box:hover {
  transform: scale(1.03);
}

.selected {
  border: 2px solid #5340d0;
}

.offer-title {
  font-size: 0.95rem;
  color: #5340d0;
  font-weight: bold;
}

.offer-price {
  margin-top: 8px;
  font-size: 0.9rem;
}

.offer-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 15px;
  margin-bottom: 35px;
  width: 100%; /* Prend la même largeur que .offer-options */
  padding: 0 15px; /* Marges symétriques, alignées avec .offer-options */
  box-sizing: border-box;
  max-width: 520px; /* Correspond à .offer-options sur grand écran */
}

.add-to-cart,
.buy-now {
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  font-size: 0.9rem;
  border-radius: 5px;
  width: 100%; /* Prend toute la largeur du conteneur */
  max-width: 520px; /* Élargit les boutons pour correspondre à .offer-options */
  box-sizing: border-box;
  text-align: center;
}

.add-to-cart {
  background-color: #5340d0;
  color: white;
}

.buy-now {
  background-color: #050505;
  color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
  .product-page {
    padding: 10px 10px;
  }

  .product-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }

  .carousel {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  .carousel-image {
    width: 70%;
    height: 70%;
  }

  .product-info {
    width: 100%;
    max-width: 700px;
    padding: 0 10px;
    margin: 0 auto;
  }

  .product-header {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .product-info h1 {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .product-price .old-price{
    margin-left: -25vw;
  }

  .color-selection {
    align-items: center;
  }

  .product-rating {
    justify-content: center;
  }

  .product-benefits {
    padding: 0 10px;
  }

  .benefit-text {
    text-align: justify;
  }

  .offer-options {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    max-width: 400px; /* Ajusté pour mobile */
  }

  .offer-box {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .offer-button-container {
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px; /* Correspond à .offer-options en mobile */
  }

  .add-to-cart,
  .buy-now {
    width: 100%;
    max-width: 400px; /* Réduit pour mobile */
    margin: 5px 0;
  }
}

/* ProductPage.css */

/* Squelette de base avec effet shimmer */
.skeleton {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 100%;
  animation: shimmer 1.5s infinite linear;
}

/* Animation shimmer */
@keyframes shimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

/* Styles pour les éléments spécifiques du squelette */
.skeleton-image {
  width: 100%;
  height: 300px; /* Ajusté pour correspondre à la taille typique du carousel */
  border-radius: 8px;
  background-color: #eee;
}

.skeleton-text {
  height: 20px;
  margin: 10px 0;
  border-radius: 4px;
  background-color: #eee;
}

.skeleton-name {
  width: 60%;
  height: 40px; /* Plus grand pour le titre */
}

.skeleton-price {
  width: 40%;
  height: 30px; /* Légèrement plus grand pour le prix */
}

.skeleton-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #eee;
  display: inline-block;
  margin-right: 10px;
}

/* Ajustements pour la mise en page du squelette */
.product-container.skeleton {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
}

.product-container.skeleton .carousel {
  flex: 1;
}

.product-container.skeleton .product-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.product-container.skeleton .color-options {
  display: flex;
  gap: 10px;
}

.product-container.skeleton .product-benefits {
  list-style: none;
  padding: 0;
}

.product-container.skeleton .offer-button-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

/* Assurez-vous que les éléments réels ne perturbent pas le squelette */
.product-container:not(.skeleton) .skeleton-image,
.product-container:not(.skeleton) .skeleton-text,
.product-container:not(.skeleton) .skeleton-color {
  display: none;
}