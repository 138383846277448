.policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'SF Pro Display', 'Helvetica Neue', Arial, sans-serif;
    color: #1d1d1f;
    text-align: left;
  }
  
  .policy-title {
    font-size: 44px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: -0.02em;
    text-align: center;
  }
  
  .policy-subtitle {
    font-size: 20px;
    font-weight: 400;
    color: #6e6e73;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .policy-section {
    margin-bottom: 40px;
  }
  
  .policy-section h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #1d1d1f;
  }
  
  .policy-section h3 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #1d1d1f;
  }
  
  .policy-section p {
    font-size: 17px;
    line-height: 1.5;
    color: #6e6e73;
    margin-bottom: 10px;
  }
  
  .policy-section ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 10px;
  }
  
  .policy-section li {
    font-size: 17px;
    color: #6e6e73;
    margin-bottom: 8px;
  }
  
  .policy-section a {
    color: #819AF1;
    text-decoration: none;
  }
  
  .policy-section a:hover {
    text-decoration: underline;
  }
  
  /* Responsive */
  @media (max-width: 600px) {
    .policy-title {
      font-size: 32px;
    }
  
    .policy-subtitle {
      font-size: 16px;
    }
  
    .policy-section h2 {
      font-size: 24px;
    }
  
    .policy-section h3 {
      font-size: 18px;
    }
  
    .policy-section p,
    .policy-section li {
      font-size: 16px;
    }
  }