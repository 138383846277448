.reviews {
    text-align: center;
    padding: 5vh 2vw; /* Use viewport units for padding */
    margin-top: 4vh;
    margin: 0 auto;
    max-width: 100vw; /* Ensure it doesn't exceed the viewport width */
    width: 100%;
    box-sizing: border-box; /* Include padding in width calculation */
}

.reviews h2 {
    font-size: 2.5rem; /* Slightly larger for better visibility */
    font-weight: bold;
    color: #000;
    margin-bottom: 1vh;
}

.stars {
    width: 10vw; /* Scale with viewport width */
    min-width: 120px; /* Ensure it doesn't get too small */
    margin: 1vh auto;
}

.review-count {
    font-size: 1rem;
    color: #555;
}

.review-count span {
    font-weight: bold;
    color: #000;
}

.trustpilot {
    font-size: 0.9rem;
    color: #888;
    margin-bottom: 2vh;
}

.reviews-grid {
    display: flex;
    flex-direction: column; /* Stack cards vertically by default */
    align-items: center;
    gap: 2vh; /* Space between cards */
    margin-top: 2vh;
    width: 100%;
    max-width: 100vw; /* Prevent overflow */
    padding: 0 1vw;
    box-sizing: border-box;
}

.review-card {
    background-color: #f9f9f9;
    padding: 2vh 2vw;
    border-radius: 12px;
    text-align: left;
    transition: transform 0.3s ease;
    width: 90vw; /* Take up most of the viewport width */
    max-width: 350px; /* Cap the width for larger screens */
    min-height: 30vh; /* Ensure a minimum height */
    box-sizing: border-box;
}

.review-card.no-image {
    min-height: 20vh; /* Smaller height for cards without images */
    padding: 1.5vh 1.5vw;
}

.review-card:hover {
    transform: translateY(-5px);
}

.review-header {
    display: flex;
    flex-direction: column;
}

.stars-small {
    width: 8vw; /* Scale with viewport */
    min-width: 80px;
    margin-bottom: 1vh;
}

.review-time {
    font-size: 0.8rem;
    color: #888;
    margin-bottom: 0.8vh;
}

.review-image {
    width: 100%;
    max-width: 20vw; /* Scale image with viewport */
    min-width: 200px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1.2vh;
    display: block;
}

.review-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.8vh;
}

.review-message {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin-bottom: 1vh;
}

.review-author {
    font-size: 0.9rem;
    color: #888;
    font-style: italic;
}

.see-more {
    margin-top: 2vh;
}

.see-more button {
    background-color: #819AF1;
    color: #fff;
    border: none;
    padding: 1vh 2vw;
    font-size: 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.see-more button:hover {
    background-color: #6550e6;
}

/* Media Queries for Responsive Design */
@media (min-width: 769px) {
    .reviews-grid {
        flex-direction: row; /* Switch to row layout for larger screens */
        flex-wrap: wrap; /* Allow cards to wrap */
        justify-content: center;
    }

    .review-card {
        width: 30vw; /* Take up a third of the viewport width */
        max-width: 300px; /* Cap the width */
    }

    .review-image {
        max-width: 15vw; /* Adjust image size for larger screens */
        min-width: 180px;
    }

    .stars {
        width: 8vw;
        min-width: 140px;
    }

    .stars-small {
        width: 6vw;
        min-width: 100px;
    }
}

@media (max-width: 768px) {
    .reviews {
        padding: 3vh 2vw;
    }

    .reviews h2 {
        font-size: 2rem;
    }

    .stars {
        width: 15vw;
        min-width: 100px;
    }

    .review-count {
        font-size: 0.9rem;
    }

    .trustpilot {
        font-size: 0.8rem;
    }

    .review-card {
        width: 85vw; /* Slightly smaller for better fit */
        max-width: 320px;
    }

    .review-card.no-image {
        min-height: 18vh;
    }

    .stars-small {
        width: 12vw;
        min-width: 80px;
    }

    .review-title {
        font-size: 1.1rem;
    }

    .review-message {
        font-size: 0.9rem;
    }

    .review-author {
        font-size: 0.8rem;
    }

    .see-more button {
        padding: 0.8vh 1.5vw;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .reviews {
        padding: 2vh 1vw;
    }

    .reviews h2 {
        font-size: 1.8rem;
    }

    .stars {
        width: 20vw;
        min-width: 90px;
    }

    .review-count {
        font-size: 0.8rem;
    }

    .trustpilot {
        font-size: 0.7rem;
    }

    .review-card {
        padding: 1.5vh 1.5vw;
        min-height: 25vh;
        width: 90vw;
        max-width: 300px;
    }

    .review-card.no-image {
        min-height: 15vh;
    }

    .review-image {
        max-width: 30vw;
        min-width: 150px;
    }
}