/* Style global pour la page */
.cart-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  margin: 0 15%;
}

.cart-page h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.cart-page p {
  font-size: 1rem;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

/* Contenu du panier */
.cart-items {
  width: 100%;
}

.cart-item {
  padding: 15px;
  padding-bottom: 5vh;
  border-bottom: 1px solid #ddd;
  background-color: white;
  margin-top: 2vh;
  border-radius: 8px;
}

/* Style pour une offre groupée (iPhone + AirPods) */
.offer-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Style pour chaque produit dans une offre groupée */
.offer-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.offer-item .cart-item-image img {
  width: 100px;
  height: 140px;
  object-fit: contain;
  border-radius: 8px;
  margin-right: 15px;
}

.offer-item .cart-item-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.offer-item .cart-item-info-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 10px;
}

.offer-item .cart-item-info-left h3,
.offer-item .cart-item-info-left p {
  margin: 0;
}

.offer-item .cart-item-info-right {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* Style pour un produit simple */
.single-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.cart-item-image img {
  width: 100px;
  height: 140px;
  object-fit: contain;
  border-radius: 8px;
  margin-right: 15px;
}

.cart-item-info {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.cart-item-info-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 10px;
}

.cart-item-info-right {
  display: flex;
  align-items: center;
  gap: 15px;
}

.cart-item-info-left h3,
.cart-item-info-left p {
  margin: 0;
}

.cart-item-quantity input {
  width: 50px;
  text-align: center;
  appearance: none;
  -webkit-appearance: none;
  background-color: #f0f0f0;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
  padding: 5px;
  transition: all 0.3s ease;
}

.cart-item-quantity input:focus {
  outline: none;
  border-color: #2A73DB;
}

.cart-item-price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2A73DB;
  text-align: right;
}

.remove-link {
  color: #2A73DB;
  text-decoration: underline;
  font-size: 1rem;
  background: none;
  border: none;
  cursor: pointer;
}

.cart-summary {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 100%;
  margin-top: 40px;
}

.cart-summary-item {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  font-size: 1.2rem;
}

.cart-summary-item p {
  margin: 0;
}

.cart-summary-item:last-child {
  border-bottom: none;
}

.cart-summary-item p:last-child {
  font-weight: bold;
}

.cart-summary .separator {
  border-top: 1px solid #ddd;
  margin: 10px 0;
}

.validate-order-button-top,
.validate-order-button {
  background-color: #2A73DB;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
  width: auto;
  max-width: 250px;
  margin-bottom: 50px;
}

.validate-order-button-top:hover,
.validate-order-button:hover {
  background-color: #1a5c9c;
}

/* Erreur */
.error-message {
  color: red;
  font-size: 1.2rem;
  margin-top: 20px;
}

.recommended-products {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
}

.recommended-product-card {
  padding: 15px;
  border-radius: 8px;
  width: calc(30% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: #fff;
  margin-left: -1vw;
  margin-right: -1vw;
}

.recommended-product-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
}

.recommended-product-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.color-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  margin: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  flex-shrink: 0;
}

.color-button.selected {
  border: 2px solid #333;
}

.add-to-cart-button {
  background-color: #2A73DB;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.add-to-cart-button:hover {
  background-color: #1a5c9c;
}

.cart-item-special-offer {
  background-color: #FFE4E1;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
}

/* Media Queries pour les écrans plus petits */
@media (max-width: 768px) {
  .cart-page {
    margin: 0 5%;
    padding: 20px;
  }

  .cart-item {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    text-align: center;
  }

  .offer-group {
    align-items: center;
  }

  .offer-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .offer-item .cart-item-info {
    flex-direction: column;
    align-items: center;
  }

  .offer-item .cart-item-info-right {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .single-item {
    flex-direction: column;
    align-items: center;
  }

  .cart-item-info {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cart-item-info-left {
    margin-bottom: 10px;
  }

  .cart-item-info-right {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .cart-item-quantity {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .cart-item-quantity input {
    width: 40px;
    margin-left: 10px;
  }

  .cart-item-price {
    margin-top: 10px;
  }

  .recommended-product-card {
    width: calc(100% - 30px);
    margin-left: 0;
    margin-right: 0;
  }

  .validate-order-button-top,
  .validate-order-button {
    width: 100%;
  }
}