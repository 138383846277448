.contact-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'SF Pro Display', 'Helvetica Neue', Arial, sans-serif;
    color: #1d1d1f;
    text-align: center;
  }
  
  .contact-title {
    font-size: 44px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: -0.02em;
  }
  
  .contact-subtitle {
    font-size: 20px;
    font-weight: 400;
    color: #6e6e73;
    margin-bottom: 40px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 60px;
  }
  
  .contact-input,
  .contact-textarea {
    padding: 14px;
    font-size: 17px;
    border: 1px solid #d2d2d7;
    border-radius: 12px;
    background-color: #f5f5f7;
    outline: none;
    transition: border-color 0.2s;
  }
  
  .contact-input:focus,
  .contact-textarea:focus {
    border-color: #819AF1;
  }
  
  .contact-textarea {
    min-height: 120px;
    resize: vertical;
  }
  
  .contact-button {
    padding: 12px 24px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #819AF1;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 200px;
    margin: 0 auto;
  }
  
  .contact-button:hover {
    background-color: #819AF1;
  }
  
  .contact-info,
  .contact-hours,
  .contact-links {
    margin-top: 40px;
  }
  
  .contact-info h3,
  .contact-hours h3,
  .contact-links h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .contact-info p,
  .contact-hours p,
  .contact-links p {
    font-size: 17px;
    color: #6e6e73;
    margin: 5px 0;
  }
  
  .contact-info a,
  .contact-links a {
    color: #819AF1;
    text-decoration: none;
  }
  
  .contact-info a:hover,
  .contact-links a:hover {
    text-decoration: underline;
  }
  
  .notification {
    position: fixed;
    top: 20px;
    left: 20px;
    background-color: #819AF1;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: fadeInOut 3s ease-in-out;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    10% {
      opacity: 1;
      transform: translateY(0);
    }
    90% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
  
  @media (max-width: 600px) {
    .contact-title {
      font-size: 32px;
    }
  
    .contact-subtitle {
      font-size: 16px;
    }
  
    .contact-input,
    .contact-textarea {
      font-size: 16px;
    }
  
    .contact-button {
      width: 100%;
    }
  }