body {
    font-family: 'Barlow', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.banner {
    background-color: #819AF1;  
    color: #fff;
    text-align: center;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner img {
    max-width: 30px;
    margin-right: 8px;
}

.promo {
    position: absolute;
    width: 100%;
    height: 120vh; 
    top: 0;
    left: 0;
    background-image: url('../images/netstock_promos.png');
    background-size: cover; 
    background-position: top center; 
    background-repeat: no-repeat; 
    z-index: -1; 
    margin: 0; 
    padding: 0;
}

@media (max-width: 768px) {
    .promo {
        background-image: url('../images/netstock_promos-mobile.png');
        height: 100vh; 
        background-position: center center;
    }
}

@media (min-width: 769px) and (max-width: 1100px) {
  .promo {
      background-image: url('../images/netstock_promos-tablet.png');
      height: 100vh; 
      background-position: center center;
  }
}

.iphone-category {
    position: relative;
    padding-left: 10%;
    padding-top: 110vh; 
    padding-right: 10%;
    text-align: left;
    z-index: 1;
}

@media (max-width: 768px) {
    .iphone-category {
        padding-top: 90vh;
    }
}

.iphone-category h1 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.iphone-category p {
    font-size: 1rem;
    margin-bottom: 40px;
}

.iphone-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
}

.iphone-card {
    background-color: #fff;
    padding: 24px;
    border-radius: 16px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
}

.iphone-card:hover {
    transform: translateY(-5px);
}

.iphone-card img {
    max-width: 70%;
    height: auto;
    margin-bottom: 16px;
}

.iphone-card h2 {
    font-size: 1.5rem;
    margin-bottom: 8px;
    color: #333;
}

.iphone-card p {
    font-size: 1.125rem;
    margin-bottom: 16px;
    color: #555;
}

.iphone-card button {
    background-color: #819AF1;
    color: #fff;
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    border-radius: 24px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.iphone-card button:hover {
    background-color: #5b7be1;
}

.iphone-specs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
}

.spec-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
}

.spec-item img {
    width: 36px;
    height: auto;
    margin-bottom: 8px;
}

.spec-item p {
    font-size: 0.875rem;
    color: #666;
    text-align: center;
}

@media (max-width: 1024px) {
    .iphone-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .iphone-card {
        padding: 20px;
    }
}

@media (max-width: 768px) {
    .iphone-grid {
        grid-template-columns: 1fr;
    }

    .iphone-card {
        padding: 16px;
    }
}

.more-productcategory{
    text-align: center;
    position: relative;
}

.iphone-family {
    padding: 5% 2.5%;
    cursor: pointer;
}

.family-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px;
}

.family-card {
    background-color: #F5F5F7;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    height: 400px; 
}

.family-card:hover {
    transform: translateY(-5px);
}

.family-card h2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: -8px;
    color: #1d1d1f;
}

.family-card p {
    font-size: 1rem;
}

.family-card .buttons {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 12px;
    z-index: 10;
}

.family-card .btn-primary,
.family-card .btn-secondary {
    padding: 10px 24px;
    border-radius: 24px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.family-card .btn-primary {
    background-color: #819AF1;
    color: #ffffff;
    border: none;
}

.family-card .btn-primary:hover {
    background-color: #6550e6;
}

.family-card .btn-secondary {
    background-color: #ffffff;
    color: #5340D0;
    border: 1px solid #819AF1;
}

.family-card .btn-secondary:hover {
    background-color: #f0f0f0;
}

.family-card img {
    width: 100%; 
    height: auto;
    object-fit: contain; 
    position: absolute;
    bottom: 0;
}

/* 🌐 Responsive */
@media (max-width: 768px) {
    .family-grid {
        grid-template-columns: 1fr; 
    }

    .family-card {
        height: 70vw; 
    }
}


.special-offer{
    margin-right: 15%;
    margin-left: 15%;
}

.faq {
    margin: 20px 0;
  }

.faq-section {
    margin-right: 15%;
    margin-left: 15%;

}
  
  .faq-item {
    background-color: #F0F0F0;
    border-radius: 12px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
  }
  
  .faq-item.open {
    background-color: #1C1C29;
    color: #ffffff;
  }
  
  .faq-question {
    padding: 15px 20px;
    font-size: 16px;
    font-weight: regular;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .faq-answer {
    padding: 10px 20px;
    font-size: 14px;
    color: #A0A0A0;
  }
  
  .chevron {
    transition: transform 0.3s ease;
  }
  
  .rotate {
    transform: rotate(180deg);
  }
  
  .see-more a {
    color: #0071e3;
    cursor: pointer;
    text-decoration: none;
  }
  
  .see-more a:hover {
    text-decoration: underline;
  }

  .order-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    padding: 40px 20px;
  }
  
  .order-steps h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  
  .steps-container {
    position: relative;
    margin-bottom: 40px;
    width: 100%;
    max-width: 1000px;
  }
  
  .steps {
    display: flex;
    position: relative;
    z-index: 1;
  }
  
  .steps-line {
    position: absolute;
    background-color: #bbb;
    transition: all 0.3s ease;
  }
  
  /* Style pour grand écran */
  @media (min-width: 769px) {
    .steps {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      flex: 1;
    }
  
    .steps-line {
      top: 12px;
      left: 50%;
      width: calc(100% - 24px);
      height: 2px;
      transform: translateX(-50%);
    }
  
    .step span {
      margin-top: 8px;
      margin-left: 0;
      font-size: 16px;
      color: #666;
      text-align: center;
    }
  }
  
  /* Style pour mobile */
  @media (max-width: 768px) {
    .steps-container {
      width: auto;
      margin-left: auto;
      margin-right: auto;
    }
  
    .steps {
      flex-direction: column;
      align-items: center;
    }
  
    .step {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      margin-bottom: 30px;
      width: 100%;
      max-width: 300px;
      position: relative; 
    }
  
    .steps-line {
      left: 12%; 
      top: 12px;
      bottom: 60px;
      width: 2px;
      transform: translateX(-50%); 
    }
  
    .step-circle {
      margin-right: 15px;
      position: relative;
      z-index: 2; 
    }
  
    .step span {
      margin-top: 0;
      margin-left: 0;
      font-size: 16px;
      color: #666;
      text-align: left;
    }
  }
  
  .step-circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #bbb;
    background-color: white;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .step.active .step-circle {
    background-color: #000;
    border-color: #000;
  }
  
  .step.active span {
    font-weight: bold;
    color: #000;
  }
  
  .step-content {
    display: flex;
    align-items: center;
    gap: 40px;
    text-align: left;
    max-width: 800px;
  }
  
  .step-content img {
    width: 250px;
    height: auto;
    border-radius: 8px;
  }
  
  .step-content p {
    font-size: 18px;
    color: #444;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .step-content {
      flex-direction: column;
      text-align: center;
    }
  }
  
  @media (max-width: 768px) {
    .steps {
      width: 100%;
      margin-bottom: 30px;
    }
  
    .step-circle {
      width: 30px;
      height: 30px;
    }
  
    .step span {
      margin-left: 10px;
      font-size: 14px;
    }
  
    .step-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 20px;
    }
  
    .step-content img {
      width: 200px;
      height: auto;
    }
  
    .step-content p {
      font-size: 16px;
    }
  }
  

.features-section {
    display: flex;
    justify-content: center;
    gap: 40px;
    padding: 40px 20px;
}

.feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 220px;
}

.feature img {
    width: 60px; 
    height: auto;
    margin-bottom: 16px;
}

.feature h3 {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin-bottom: 8px;
}

.feature p {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .features-section {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

.visit-container {
  display: flex;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 10vh;
}

.visit-image {
  flex: 1;
  padding-right: 50px;
}

.visit-image img {
  border-radius: 10px;
  max-width: 100%;
  height: auto;
}

.visit-text {
  flex: 1;
}

.visit-text h2 {
  margin-bottom: 15px;
  color: #333;
}

.visit-text p {
  line-height: 1.6;
  color: #666;
}

@media (max-width: 768px) {
  .visit-container {
    flex-direction: column;
    text-align: center;
  }

  .visit-image {
    padding-right: 0;
    margin-bottom: 20px;
  }
}

.purchase-notification {
  position: fixed;
  top: clamp(10px, 2vw, 20px); /* Ajustement fluide entre 10px et 20px */
  right: clamp(10px, 2vw, 20px);
  background-color: #2BC740;
  color: #fff;
  padding: clamp(8px, 1.5vw, 10px) clamp(15px, 2.5vw, 20px); /* Padding adaptatif */
  border-radius: 8px;
  font-size: clamp(14px, 1.2rem, 16px); /* Taille de police flexible */
  font-weight: 500;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
  max-width: 90%; /* Empêche de dépasser l'écran sur mobile */
  box-sizing: border-box; /* Inclut le padding dans la largeur */
}

/* Animation inchangée */
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

/* Ajustements pour écrans plus petits */
@media screen and (max-width: 768px) {
  .purchase-notification {
    top: 10px;
    right: 10px;
    padding: 8px 15px;
    font-size: 14px;
  }
}

/* Pour très petits écrans (mobile) */
@media screen and (max-width: 480px) {
  .purchase-notification {
    top: 5px;
    right: 5px;
    padding: 6px 12px;
    font-size: 12px;
    border-radius: 6px;
  }
}


