/* CataloguePage.css */

/* Conteneur principal de la page */
.catalogue-page {
  padding: 20px;
  margin: 0 10%; /* Marge de 10% à gauche et à droite */
}

/* Conteneur de recherche */
.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.search-input {
  position: absolute;
  right: 30px;
  width: 200px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 1rem;
  outline: none;
  transition: width 0.3s ease;
}

.search-input:focus {
  width: 250px;
  border-color: #666;
}

.no-results {
  text-align: center;
  color: #666;
  font-size: 1.2rem;
  margin: 40px 0;
  margin-top: 10vh;
  margin-bottom: 17.5vh;
}

.catalogue-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left; /* Aligné à gauche */
}

.catalogue-description {
  font-size: 1.1rem;
  margin-bottom: 30px;
  color: #555;
  text-align: left; /* Aligné à gauche */
}

/* Section de catégorie */
.category-section {
  margin-bottom: 40px;
}

.category-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* Liste des produits */
.product-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cartes par ligne par défaut */
  gap: 20px;
  justify-items: center;
}

/* Adaptation responsive */
@media (max-width: 1024px) {
  .product-list {
      grid-template-columns: repeat(2, 1fr); /* 2 cartes par ligne sur les écrans plus petits */
  }
}

@media (max-width: 600px) {
  .product-list {
      grid-template-columns: 1fr;
  }
  .search-input {
      width: 150px;
  }
  
  .search-input:focus {
      width: 180px;
  }
}

/* Carte produit */
.product-card {
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 300px;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-image {
  max-width: 300px;
  max-height: 150px;
  border-radius: 8px;
}

.placeholder-image {
  width: 100%;
  height: 200px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa;
  font-size: 1rem;
  border-radius: 8px;
}

.product-info {
  margin-top: 15px;
}

.product-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.product-price {
  font-size: 1.1rem;
  margin-bottom: 15px;
  margin-left: 30%;
}

/* Section des couleurs sous chaque produit */
.color-options {
  display: flex;
  justify-content: center;
  gap: 1px;
}

.color-button {
  width: 20px;
  height: 20px;
  border-radius: 50%; /* Boutons ronds */
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.color-button:hover {
  transform: scale(1.1);
}

/* Sélection de couleur active */
.color-button.selected {
  border: 2px solid #000;
}

@media (max-width: 1200px) {
  .product-price {
      margin-left: 1vw;
  }
}


/* Responsive pour les petits écrans */
@media (max-width: 600px) {
  .product-card {
      padding: 15px;
  }

  .catalogue-title {
      font-size: 1.5rem;
  }

  .catalogue-description {
      font-size: 1rem;
  }

  .category-name {
      font-size: 1.2rem;
  }

  .product-name {
      font-size: 1rem;
  }

  .no-results {
    margin-bottom: 5vh;
  }

  .product-price {
      font-size: 1rem;
  }
}

@keyframes shimmer {
  0% {
      background-position: -468px 0;
  }
  100% {
      background-position: 468px 0;
  }
}

.skeleton {
  background: #f6f7f8;
  background-image: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 100%;
  animation: shimmer 1.5s infinite linear;
}

.skeleton-image {
  width: 100%;
  height: 150px;
  border-radius: 8px;
  background-color: #eee;
}

.skeleton-text {
  height: 20px;
  margin: 10px 0;
  border-radius: 4px;
  background-color: #eee;
}

.skeleton-name {
  width: 80%;
}

.skeleton-price {
  width: 40%;
}

.skeleton-category-name {
  height: 30px;
  width: 200px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #eee;
}

.skeleton-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #eee;
}

.catalogue-search-container {
  margin: 20px 0;
  text-align: center;
}

.catalogue-search-input {
  padding: 10px;
  width: 300px;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}