/* footer */
.footer {
    background-color: #5340D0;
    color: #fff;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-section {
    display: flex;
    flex-direction: column;
}

.footer-section h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 16px;
}

.footer-section h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-section ul li {
    margin-bottom: 8px;
}

.footer-section ul li a {
    color: #ccc;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.2s ease-in-out;
}

.footer-section ul li a:hover {
    color: #fff;
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #ccc;
}

/* Responsive */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        gap: 20px;
    }

    .footer-section {
        align-items: center;
        text-align: center;
    }
}