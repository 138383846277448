/* Base de la navbar */
.navbar {
  background-color: transparent;
  padding: 16px 32px;
  position: relative;
  top: 0;
  z-index: 50;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Nom du site */
.navbar .brand {
  font-size: 1.5rem;
  font-weight: bold;
}

/* Icônes */
.navbar .icons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.navbar .icons img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.cart-icon {
  position: relative;
}

.cart-count {
  position: absolute;
  top: -5px;
  right: -15px;
  font-size: 12px;
  font-weight: bold;
  padding: 3px 6px;
  border-radius: 50%;
}

@media (min-width: 769px) {
  .cart-count {
    right: -15px; /* Décalage uniquement sur PC */
  }
}

.cart-count.dark {
  background-color: black;
  color: white;
}

.cart-count.light {
  background-color: white;
  color: black;
}

/* Liens desktop */
.navbar .links {
  display: flex;
  gap: 24px;
}

.navbar .links a {
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s;
}

.navbar.dark .brand,
.navbar.dark .links a {
  color: #000;
}

.navbar.light .brand,
.navbar.light .links a {
  color: #fff;
}

/* === MENU BURGER === */
.menu-icon {
  display: none;
  cursor: pointer;
}

.bar {
  width: 24px;
  height: 2px;
  background-color: black;
  margin: 5px 0;
  transition: 0.3s;
}

.bar.dark {
  background-color: black;
}

.bar.light {
  background-color: white;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

/* === Popup Menu === */
.popup-menu {
  position: fixed;
  top: 0; /* Changé de bottom à top */
  left: 0;
  width: 100%;
  height: 50vh; /* Prend toute la hauteur de l'écran */
  padding: 16px;
  background-color: #ffffff;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centre verticalement */
  gap: 16px;
  box-sizing: border-box;
  overflow: hidden; /* Pas de scroll interne */
}

/* Bloquer le scroll quand le menu est ouvert */
body.menu-open {
  overflow: hidden;
  position: fixed; /* Fige la position de la page */
  width: 100%; /* Empêche tout décalage */
  height: 100%; /* S'assure que la hauteur est fixe */
  top: 0;
  left: 0;
  -webkit-overflow-scrolling: touch; /* Pour les navigateurs mobiles */
}

.popup-menu a {
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  color: black;
  padding: 12px 16px;
  border-radius: 8px;
  transition: background-color 0.2s;
}

.popup-menu a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.popup-menu.dark a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.popup-menu a:first-child,
.popup-menu a:last-child {
  margin: 0; /* Supprime les marges spécifiques */
}

/* Bouton Fermer */
.close-btn {
  position: absolute;
  top: 16px; /* Éloigne du bord supérieur */
  right: 16px; /* Éloigne du bord droit */
  background: none;
  border: none;
  font-size: 2rem; /* Augmente la taille pour plus de visibilité */
  cursor: pointer;
  color: black;
  z-index: 101; /* S'assure qu'elle est au-dessus des autres éléments */
}

/* Mode mobile */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .navbar .brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .navbar .links {
    display: none;
  }

  .navbar .icons {
    position: absolute;
    right: 16px;
  }
}

/* Mode Desktop */
@media (min-width: 769px) {
  .navbar {
    justify-content: space-between;
  }

  .navbar .brand {
    position: relative;
    left: 0;
    transform: none;
  }

  .navbar .links {
    display: flex;
  }
}